import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios';
import ApiUrl from '../../../services/ApiUrl';
import ChatCard from '../../../components/chat-card/chatCard';
import Pagination from '../../../components/pagination/Pagination';
import LiveChat from '../../../components/live-chat/LiveChat';
import PageTitle from './../../../components/page-title/PageTitle';
import Loader from './../../../components/loader/Loader';
import Popup from '../../../components/popup/Popup';

const Chat = () => {
    let [ load, setLoad ] = useState();
    let [ loader, setLoader ] = useState(true);
    let [ loadSockets, setLoadSockets ] = useState(false);
    let [ closePopup, setClosePopup ] = useState(false);
    const dispatch = useDispatch();
    let [ changeOpenedChats, setChangeOpenedChats ] = useState(0);
    // let chats = useSelector(state => state.chats);
    let [ chats, setChats ] = useState([]);
    let [ test, setTest ] = useState([]);
    // let socket = useSelector(state => state.socket);
    let user = useSelector(state => state.userData );
    let countData = useSelector(state => state.countData);
    let openedChats = useSelector(state => state.openedChats );
    let [ loadFront, setLoadFront ] = useState(false);

    // Waiting
    let [ currentPageWait, setCurrentPageWait ] = useState(1);
    let [ pageSizeWait, setPageSizeWait ] = useState(10);
    let [ maxPagesWait, setMaxPagesWait ] = useState(1);

    // OpenedUser
    let [ currentPageOpenedUser, setCurrentPageOpenedUser ] = useState(1);
    let [ pageSizeOpenedUser, setPageSizeOpenedUser ] = useState(10);
    let [ maxPagesOpenedUser, setMaxPagesOpenedUser ] = useState(1);

    // OpenedOther
    let [ currentPageOpenedOther, setCurrentPageOpenedOther ] = useState(1);
    let [ pageSizeOpenedOther, setPageSizeOpenedOther ] = useState(10);
    let [ maxPagesOpenedOther, setMaxPagesOpenedOther ] = useState(1);

    // Closed
    let [ currentPageClosed, setCurrentPageClosed ] = useState(1);
    let [ pageSizeClosed, setPageSizeClosed ] = useState(10);
    let [ maxPagesClosed, setMaxPagesClosed ] = useState(1);

    useEffect(() => {
        if ( load && !loadSockets ) {
            // initSockets();
            listenOpenedChats();
            setLoadSockets(true);
        }
    }, [ chats, load ]);

    useEffect(() => {
        if ( !load ) {
            setLoader(true);
            getChatsOnPageLoad()
            setLoad(true);
        }
    }, [ load ]);


    useEffect(() => {
        // console.log(openedChats)
    }, [ openedChats ])

    useEffect(() => {
        // console.log('podmieniło')
        // console.log(chats)
    }, [ chats ])

    let getChatsOnPageLoad = async () => {
        await getChats( 'waiting', currentPageWait, pageSizeWait );
        await getChats( 'opened-other', currentPageOpenedOther, pageSizeOpenedOther ); 
        await getChats( 'opened-user', currentPageOpenedUser, pageSizeOpenedUser ); 
        await getChats( 'closed', currentPageClosed, pageSizeClosed ); 
        await setLoadFront(true);
        await setLoader(false);
    }

    let initSockets = async () => {
        // socket.emit('subscribe-room', 1);
        await listenCreatedRoom();
        await listenEditedRoom();
    }

    let listenOpenedChats = async() => {
        let tempOpened = openedChats;
        let newArray = [];
        await dispatch( { type: 'OPENED_CHATS', value: [] } )
        if ( tempOpened && tempOpened.length > 0) {
            await tempOpened.forEach( el => {
                newArray = [...newArray, el];
            });
        } 
        await dispatch( { type: 'OPENED_CHATS', value: newArray } )
    }

    let getChats = async ( status, page, pageSize, update = false ) => {
        let tempChats = chats;
        if ( update ) {
            tempChats = await chats.filter( el => {
                let tempStatus = status === 'opened-user' || status === 'opened-other' ? 'opened' : status;
               
                if ( status === 'opened-user') {
                    return ( ( tempStatus.toLowerCase() !== el.status_chat.toLowerCase() ) || ( tempStatus.toLowerCase() === el.status_chat.toLowerCase() && !el.isYour ) )
                } else if( 'opened-other' ) { 
                    return ( ( tempStatus.toLowerCase() !== el.status_chat.toLowerCase() ) || ( tempStatus.toLowerCase() === el.status_chat.toLowerCase() && el.isYour ) )
                } else {
                    return tempStatus.toLowerCase() !== el.status_chat.toLowerCase();
                }
            })
        }

        await axios({
            method: 'get',
            url: `${ ApiUrl() }/chatsPagination?status=${ status }&page=${ page }&pageSize=${ pageSize }&userId=${ user.id }`,
            headers: { Authorization: `Bearer ${ localStorage.getItem('token') }` }
        }).then( async response => {
            let res = JSON.parse(response.request.response);
            let tempData = [...res[status].data];
            // if ( chats.length > 0 ) {
            //     tempChats = [ ...chats, ...res[status].data ]
            // } else {
            //     tempChats = res[status].data;
              
            // }
            switch (status) {
                case 'waiting':
                    setMaxPagesWait( res[status].last_page )
                    await tempData.map(el => {
                        el['isYour'] = false
                    })
                    break;
                
                case 'opened-user':
                    setMaxPagesOpenedUser( res[status].last_page )
                    await tempData.map(el => {
                        el['isYour'] = true
                    })
                    break;

                case 'opened-other':
                    setMaxPagesOpenedOther( res[status].last_page )
                    await tempData.map(el => {
                        el['isYour'] = false
                    })
                    break;

                case 'closed':
                    setMaxPagesClosed( res[status].last_page )
                    await tempData.map(el => {
                        el['isYour'] = false
                    })
                    break;
            
                default:
                    break;
            }
            tempChats.push(...tempData);
        });
        // await dispatch({ type: 'CHATS', value: tempChats });
        setChats(tempChats);
    }

    let updateChatsOnPaginateChange = async ( pageNumber, size = false, status ) => {
        await setLoader(true);
        switch (status) {
            case 'waiting':
                await setCurrentPageWait( pageNumber );
                size && await setPageSizeWait( size );
                break;

            case 'opened-user':
                await setCurrentPageOpenedUser( pageNumber );
                size && await setPageSizeOpenedUser( size );
                break;

            case 'opened-other':
                    await setCurrentPageOpenedOther( pageNumber );
                    size && await setPageSizeOpenedOther( size );
                    break;

            case 'closed':
                await setCurrentPageClosed( pageNumber );
                size && await setPageSizeClosed( size );
                break;
        
            default:
                break;
        }

        await getChats( status, pageNumber, size, true )
        await setLoader(false);
    }

    let UpdateEditedRoom = async room  => {
        let searchedID = room;

        axios({
            method: 'post',
            url: `${ ApiUrl() }/getChatByID`,
            data: {
                token: localStorage.getItem('token'),
                chat_id: searchedID, 
            }
        }).then(async response => {
        //    await getChats();            
        })
        
       
    }
    
    let getCreatedRoom = (chats, roomId) => {
        axios({
            method: 'post',
            url: `${ ApiUrl() }/getChatByID`,
            data: {
                token: localStorage.getItem('token'),
                chat_id: roomId, 
            }
        }).then(async response => {
            // console.log(response);  
            // setChats(null);
            setChats(chatsArray => [response.data.data[0], ...chatsArray]);
        })
    }


    let listenCreatedRoom = () => {
        // socket.on('created-room', data => {
        //     getCreatedRoom(chats, data.roomID);
        // })
    };

    let listenEditedRoom = () => {
        // socket.on('edited-room', data => {
        //     UpdateEditedRoom(data)
        // })
    };

    


    let openChat = ( id ) => {
        let newArray = []
        if ( openedChats && openedChats.length > 0) {
            newArray = [ ...openedChats, id];
        } else {
            newArray = [id]
        }
        dispatch( { type: 'OPENED_CHATS', value: newArray } )
    }

    let editChat = ( chatId, moderatorId, statusChat, removeChat = false , moderatorEnd = false) => {
        if ( openedChats.indexOf( chatId ) === -1  && statusChat === 'closed') {
            return false;
        }
        axios({
            method: 'post',
            url: `${ ApiUrl() }/editChat`,
            data: {
                token: localStorage.getItem('token'),
                chat_id: chatId, 
                moderator_id: moderatorEnd ? user.id : moderatorId, 
                status_chat: statusChat == "ban" ? "closed" : statusChat,
                moderatorEnd: moderatorEnd,
            }
        }).then(async response => {
            let res = response.data;
            if ( res.status === 'success' ) {
                let searchedID = chatId;
                let chatsCopy = chats;
                await chats.forEach( ( el, i ) => {
                    if ( el.id === searchedID ) {
                        chatsCopy[i] = res.data[0];
                    }
                });
                // await dispatch( { type: 'CHATS', value: null } );
                // await dispatch( { type: 'CHATS', value: chatsCopy } );
                setChats(null);
                setChats(chatsCopy);
                // socket.emit('edit-room', res.data[0].id);
                if ( statusChat === 'ban' ) {
                    // socket.emit('ban-user', { id: res.data[0].id, moderator: user.id });
                    let count = ++countData['chat-bans'];
                    countData['chat-bans'] = count;
                    dispatch({ type: 'COUNT_DATA', value: countData })
                }
                if ( statusChat === 'closed' ) {
                    // socket.emit('close-chat', res.data[0].id );
                }
                if ( moderatorId && !removeChat ) {
                    await openChat( searchedID );
                    // socket.emit('new-moderator', { id: res.data[0].id, moderator: moderatorId } );
                } else {
                    await closeChat( res.data[0].id );
                }
            }
        })
    }

    let closeChat = async(id) => {
        let copyArray = [...openedChats];
        let searchID = openedChats ? openedChats.indexOf(id) : -1;
        if ( searchID > -1 ) {
            copyArray.splice( searchID, 1);
        }
        await dispatch( { type: 'OPENED_CHATS', value: [] } )
        await dispatch( { type: 'OPENED_CHATS', value: copyArray && copyArray.length > 0 ? copyArray : [] } )
        await setChangeOpenedChats( changeOpenedChats + 1 );
        // await dispatch( { type: 'OPENED_CHATS_REMOVE', value: copyArray && copyArray.length > 0 ? searchID : [] } )
    }

    return (
        <div className="container">
            { closePopup &&
                <Popup 
                    close={ () => setClosePopup(false) } 
                    action={ () => { editChat(closePopup, user.id, 'deleted', true); setClosePopup(false) } }
                    btnText={"Potwierdzam"}
                    editor={"<h2>Na pewno chcesz usunąć chat?</h2>"} 
                />
            }
            <PageTitle title="Chat" />
            {loader &&
                <div className="loader-wrapper">
                    <div className="loader">
                        <Loader />
                    </div>
                </div>
            }
            {loadFront &&
                <div className="chat-section">
                    <h2 className="reset-top-margin">Oczekujące czaty</h2>
                    <hr />
                    <div className="chats">
                        { chats && chats.map( el => (
                            <>
                                { el && el.status_chat === 'waiting' &&
                                    <ChatCard openChat={ () => openChat(el.id) } 
                                                enterChat={ () => editChat(el.id, user.id, 'opened') }
                                                deleteChat={ () => setClosePopup(el.id) }  
                                                data={ el } key={ el.id } />
                                }
                            </>
                        ))}                    
                    </div>
                    <div className="pagination-wrap">
                        <Pagination currentPage={ currentPageWait }
                            maxPages={ maxPagesWait } 
                            pageSize={ pageSizeWait }
                            setPageSize={ ( size ) => updateChatsOnPaginateChange( 1, size, 'waiting') } 
                            changeCurrentPage={ ( newCurrent ) => updateChatsOnPaginateChange( newCurrent, pageSizeWait, 'waiting') } />
                    </div>

                    <h2>Trwające czaty</h2>
                    <hr />
                    <p className="subtitle">Twoje czaty: </p>
                    <div className="chats">
                            { chats && chats.map( el => (
                            <>
                                
                                { el && ( el.status_chat === 'opened' && el.moderator && el.moderator.id === user.id ) &&
                                    <ChatCard openChat={ () => openChat(el.id) } 
                                                enterChat={ () => editChat(el.id, user.id, 'opened') }
                                                deleteChat={ () => setClosePopup(el.id) }  
                                                opened data={ el } user={ user } key={ el.id } />
                                }
                            </>
                        ))}
                    </div>
                    <div className="pagination-wrap">
                        <Pagination currentPage={ currentPageOpenedUser }
                            maxPages={ maxPagesOpenedUser } 
                            pageSize={ pageSizeOpenedUser }
                            setPageSize={ ( size ) => updateChatsOnPaginateChange( 1, size, 'opened-user') } 
                            changeCurrentPage={ ( newCurrent ) => updateChatsOnPaginateChange( newCurrent, pageSizeOpenedUser, 'opened-user') } />
                    </div>


                    <p className="subtitle">Pozostałe: </p>
                    <div className="chats">
                        { chats && chats.map( el => (
                            <>
                                { el && ( el.status_chat === 'opened' &&  el.moderator.id !== user.id) &&
                                    <ChatCard openChat={ () => openChat(el.id) } 
                                                enterChat={ () => editChat(el.id, user.id, 'opened') }
                                                deleteChat={ () => setClosePopup(el.id) }  
                                                opened data={ el } key={ el.id } />
                                }
                            </>
                        ))}
                    </div>
                    <div className="pagination-wrap">
                        <Pagination currentPage={ currentPageOpenedOther }
                            maxPages={ maxPagesOpenedOther } 
                            pageSize={ pageSizeOpenedOther }
                            setPageSize={ ( size ) => updateChatsOnPaginateChange( 1, size, 'opened-other') } 
                            changeCurrentPage={ ( newCurrent ) => updateChatsOnPaginateChange( newCurrent, pageSizeOpenedOther , 'opened-other') } />
                    </div>

                

                    
                    <h2 className="subtitle">Zakończone czaty</h2>
                    <hr />
                    <div className="chats">
                        { chats && chats.map( el => (
                            <>
                                { el && el.status_chat === 'closed' &&
                                    <ChatCard openChat={ () => openChat(el.id) } 
                                                enterChat={ () => editChat(el.id, user.id, 'opened') }
                                                deleteChat={ () => setClosePopup(el.id) }  
                                                closed key={ el.id } data={ el } />
                                }
                            </>
                        ))}
                    </div>
                    <div className="pagination-wrap">
                        <Pagination currentPage={ currentPageClosed }
                            maxPages={ maxPagesClosed } 
                            pageSize={ pageSizeClosed }
                            setPageSize={ ( size ) => updateChatsOnPaginateChange( 1, size, 'closed') } 
                            changeCurrentPage={ ( newCurrent ) => updateChatsOnPaginateChange( newCurrent, pageSizeClosed , 'closed') } />
                    </div>


                    <div className="opened-chats">
                        {/* {  changeOpenedChats >= 0 && openedChats && openedChats.map( ( el, i ) => (
                                <>
                                <LiveChat   data={ el } 
                                            key={ i }
                                            closeChat={ () => closeChat(el) } 
                                            leaveChat={ () => editChat( el, null, 'waiting' ) }
                                            endChat={ (moderatorClicked) => { editChat( el, null, 'closed', false, (moderatorClicked ? true : false)) } }
                                            banUser={ () => editChat(el, null, 'ban', false, true) } 
                                            socket={ socket } />
                                </>
                            ))
                        } */}
                    </div>
            </div>
        }
    </div>
    );
}

export default Chat;