import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom"
import clientLogo from './../../../images/client-logo.png';
import clientLogoBlack from './../../../images/client-logo-black.png';
// import Logo from './../../../svg-components/Logo';
import Logo from './../../../images/logo.png';
import { useSelector } from 'react-redux'
import Arrow from './../../../svg-components/Arrow';

const Navbar = (props) => {
    let [ load, setLoad ] = useState(false);
    let [ toggleMenu, setToggleMenu ] = useState(false);
    let userPermissions = useSelector(state => state.userPermissions);
    let [ active, setActive ] = useState(false)
    let [ activeChildren, setActiveChildren ] = useState(false)

    let contrast = useSelector(state => state.contrast);

    useEffect(() => {
        if(!load) {
            if (window.matchMedia("(max-width: 1300px)").matches) {
                setToggleMenu(true)
              } else {
                setToggleMenu(false)
            }
            setLoad(true)
        }
    }, [load])

    useEffect(() => {
        if(typeof document !== "undefined") {
            if(toggleMenu) {
                document.getElementsByTagName('main')[0]?.classList.add("minimalize-menu")
            }else{
                document.getElementsByTagName('main')[0]?.classList.remove("minimalize-menu")
            }
        }
    }, [toggleMenu])

    useEffect(() => {
        let arrayLocation = props.location.pathname.split('/');
        let arrayLength = arrayLocation.length;

        if(arrayLength == 4) {
            setActive(arrayLocation[arrayLength - 2]);
            setActiveChildren(arrayLocation[arrayLength - 1]);
        }else{
            setActive(arrayLocation[arrayLength - 1]);
            setActiveChildren(false);
        }
    }, [props.location])

    let handleKeyDownSlide = (e) => {
        if (e.key === 'Enter') {
            setToggleMenu(!toggleMenu)
        }
    }

    
    return (
        <>
            <div className={toggleMenu ? "ghost-menu minimalize" : "ghost-menu"} />
            <nav className={toggleMenu ? "menu minimalize" : "menu"} id="menu">
                <div className="menu-wrapper">
                    <div className="client">
                        <img src={ Logo } alt="norddigital" />
                        {/* <Logo /> */}
                    </div>
                    <div className="slide-wrapper-btn" tabIndex="0" aria-label={toggleMenu ? "Rozwiń menu" : "Zwiń menu"} role="button" onClick={ () => setToggleMenu(!toggleMenu) } onKeyDown={(e) => handleKeyDownSlide(e)}>
                        <div className="slide-menu">
                            <Arrow />
                        </div>
                    </div>
                    <div className="menu-box">
                        <ul>
                            <li className={active == "dashboard" ? "active" : ""}>
                                <Link to="/dashboard" tabIndex="0" role="button" aria-label="Strona główna">
                                    <i className="material-icons" aria-hidden={true}>dashboard</i>
                                    <span>Strona główna</span>
                                </Link>
                            </li>
                            {userPermissions && userPermissions.includes("page-dashboard") && !userPermissions.includes("page-users") &&
                            <li id="profil" className={active == "profil" ? "active" : ""}>
                                <Link to="/dashboard/profil" tabIndex="0" role="button" aria-label="Twój profil">
                                    <i className="material-icons" aria-hidden={true}>person</i>
                                    <span>Twój profil</span>
                                </Link>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-users") &&
                            <li id="users-menu" className={active == "users" ? "active" : ""}>
                                <Link to="/dashboard/users" tabIndex="0" role="button" aria-label="Lista użytkowników" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>people</i>
                                    <span>Użytkownicy</span>
                                </Link>
                                <ul>
                                    <li className={active == "users" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/users/new" tabIndex="0" role="button" aria-label="Dodaj nowego użytkownika">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowy</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-library") &&
                            <li id="library-menu" className={active == "media-library" ? "active" : ""}>
                                <Link to="/dashboard/media-library" tabIndex="0" role="button" aria-label="Biblioteka mediów">
                                    <i className="material-icons" aria-hidden={true}>slideshow</i>
                                    <span>Biblioteka mediów</span>
                                </Link>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-pages") &&
                            <li id="pages-menu" className={active == "pages" ? "active" : ""}>
                                <Link to="/dashboard/pages" tabIndex="0" role="button" aria-label="Lista stron" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>pageview</i>
                                    <span>Strony</span>
                                </Link>
                                <ul>
                                    <li className={active == "pages" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/pages/new" tabIndex="0" role="button" aria-label="Dodaj nową stronę">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowa strona</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-articles") &&
                            <li id="articles-menu" className={active == "articles" ? "active" : ""}>
                                <Link to="/dashboard/articles" tabIndex="0" role="button" aria-label="Lista wpisów" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>article</i>
                                    <span>Wpisy</span>
                                </Link>
                                <ul>
                                    <li className={active == "articles" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/articles/new" tabIndex="0" role="button" aria-label="Dodaj nowy wpis">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowy wpis</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-hotels") &&
                            <li id="articles-menu" className={active == "hotels" ? "active" : ""}>
                                <Link to="/dashboard/hotels" tabIndex="0" role="button" aria-label="Lista hoteli" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>article</i>
                                    <span>Hotele</span>
                                </Link>
                                <ul>
                                    <li className={active == "brands" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/hotels/new" tabIndex="0" role="button" aria-label="Dodaj nowy hotel">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowy hotel</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-events") &&
                            <li id="articles-menu" className={active == "events" ? "active" : ""}>
                                <Link to="/dashboard/events" tabIndex="0" role="button" aria-label="Lista eventów" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>article</i>
                                    <span>Eventy</span>
                                </Link>
                                <ul>
                                    <li className={active == "events" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/events/new" tabIndex="0" role="button" aria-label="Dodaj nowy event">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowy event</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            }
                            {/* {userPermissions && userPermissions.includes("page-colors") &&
                            <li id="articles-menu" className={active == "colors" ? "active" : ""}>
                                <Link to="/dashboard/colors" tabIndex="0" role="button" aria-label="Lista kolorów" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>article</i>
                                    <span>Kolory</span>
                                </Link>
                                <ul>
                                    <li className={active == "colors" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/colors/new" tabIndex="0" role="button" aria-label="Dodaj nowy kolor">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowy kolor</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            } */}
                            {/* {userPermissions && userPermissions.includes("page-collections") &&
                            <li id="articles-menu" className={active == "collections" ? "active" : ""}>
                                <Link to="/dashboard/collections" tabIndex="0" role="button" aria-label="Lista kolekcji" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>article</i>
                                    <span>Kolekcje</span>
                                </Link>
                                <ul>
                                    <li className={active == "collections" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/collections/new" tabIndex="0" role="button" aria-label="Dodaj nową kolekcje">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowa kolekcja</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            } */}
                            {/* {userPermissions && userPermissions.includes("page-technical-description") &&
                            <li id="articles-menu" className={active == "technical-description" ? "active" : ""}>
                                <Link to="/dashboard/technical-description" tabIndex="0" role="button" aria-label="Lista opisów technicznych" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>article</i>
                                    <span>Opisy techniczne</span>
                                </Link>
                                <ul>
                                    <li className={active == "technical-description" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/technical-description/new" tabIndex="0" role="button" aria-label="Dodaj nowy opis techniczny">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowy opis techniczny</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            } */}
                            {/* {userPermissions && userPermissions.includes("page-marketing-support") &&
                            <li id="articles-menu" className={active == "marketing-support" ? "active" : ""}>
                                <Link to="/dashboard/marketing-support" tabIndex="0" role="button" aria-label="Lista marketing support" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>article</i>
                                    <span>Marketing Support</span>
                                </Link>
                                <ul>
                                    <li className={active == "marketing-support" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/marketing-support/new" tabIndex="0" role="button" aria-label="Dodaj nowy marketing support">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowy marketing support</span>
                                        </Link> 
                                    </li>
                                    <li className={active == "marketing-support" && activeChildren == "excel" ? "active" : ""}>
                                        <Link to="/dashboard/marketing-support/excel" tabIndex="0" role="button" aria-label="Import/Export excela">
                                            <i className="material-icons" aria-hidden={true}>import_export</i>
                                            <span>Import/Export</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            } */}
                            {/* {userPermissions && userPermissions.includes("page-human-touch") &&
                            <li id="articles-menu" className={active == "human-touch" ? "active" : ""}>
                                <Link to="/dashboard/human-touch" tabIndex="0" role="button" aria-label="Lista human touch" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>article</i>
                                    <span>Human Touch</span>
                                </Link>
                                <ul>
                                    <li className={active == "human-touch" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/human-touch/new" tabIndex="0" role="button" aria-label="Dodaj nowy human touch">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowy human touch</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            } */}
                            {/* {userPermissions && userPermissions.includes("page-realizations") &&
                            <li id="articles-menu" className={active == "realizations" ? "active" : ""}>
                                <Link to="/dashboard/realizations" tabIndex="0" role="button" aria-label="Lista realizacji" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>article</i>
                                    <span>Realizacje</span>
                                </Link>
                                <ul>
                                    <li className={active == "realizations" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/realizations/new" tabIndex="0" role="button" aria-label="Dodaj nową realizacje">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowa realizacja</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            } */}
                            {userPermissions && userPermissions.includes("page-landing") &&
                            <li id="articles-menu" className={active == "landing" ? "active" : ""}>
                                <Link to="/dashboard/landing" tabIndex="0" role="button" aria-label="Lista landingów" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>article</i>
                                    <span>Landing Page</span>
                                </Link>
                                <ul>
                                    <li className={active == "landing" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/landing/new" tabIndex="0" role="button" aria-label="Dodaj nowy landing">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowy landing</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-translates") &&
                            <li id="pages-translates" className={active == "translates" ? "active" : ""}>
                                <Link to="/dashboard/translates" tabIndex="0" role="button" aria-label="Lista tłumaczeń" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>g_translate</i>
                                    <span>Tłumaczenia</span>
                                </Link>
                                <ul>
                                    <li className={active == "translates" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/translates/new" tabIndex="0" role="button" aria-label="Dodaj nowe tłumaczenie">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowe tłumaczenie</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-menus") &&
                            <li id="menus-menu" className={active == "menus" ? "active" : ""}>
                                <Link to="/dashboard/menus" tabIndex="0" role="button" aria-label="Tworzenie nawigacji">
                                    <i className="material-icons" aria-hidden={true}>menu_open</i>
                                    <span>Menu</span>
                                </Link>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-chats") &&
                            <li id="chats-menu" className={active == "chat" ? "active" : ""}>
                                <Link to="/dashboard/chat" tabIndex="0" role="button" aria-label="Lista czatów">
                                    <i className="material-icons" aria-hidden={true}>question_answer</i>
                                    <span>Chat</span>
                                </Link>
                                {/* <ul>
                                    <li className={active == "chat" && activeChildren == "bans" ? "active" : ""}>
                                        <Link to="/dashboard/chat/bans" tabIndex="0" role="button" aria-label="Zablokowani użytkownicy chatu">
                                            <i className="material-icons" aria-hidden={true}>block</i>
                                            <span>Zablokowani użytkownicy</span>
                                        </Link> 
                                    </li>
                                </ul> */}
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-categories") &&
                            <li id="settings-menu" className={active == "categories" ? "active" : ""}>
                                <Link to="/dashboard/categories" tabIndex="0" role="button" aria-label="Kategorie">
                                    <i className="material-icons" aria-hidden={true}>category</i>
                                    <span>Kategorie</span>
                                </Link>
                            </li>
                            }
                            {userPermissions && userPermissions.includes("page-settings") &&
                            <li id="settings-menu" className={active == "settings" ? "active" : ""}>
                                <Link to="/dashboard/settings" tabIndex="0" role="button" aria-label="Ustawienia">
                                    <i className="material-icons" aria-hidden={true}>settings</i>
                                    <span>Ustawienia</span>
                                </Link>
                            </li>
                            }
                            {/* {userPermissions && userPermissions.includes("page-questions") &&
                            <li id="questions-autotests" className={active == "questions" ? "active" : ""}>
                                <Link to="/dashboard/questions" tabIndex="0" role="button" aria-label="Pytania do autotestów" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>question_answer</i>
                                    <span>Pytania do autotestów</span>
                                </Link>
                                <ul>
                                    <li className={active == "questions" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/questions/new" tabIndex="0" role="button" aria-label="Dodaj nowe pytanie">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowe pytanie</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            } */}
                            {/* {userPermissions && userPermissions.includes("page-clinics") &&
                            <li id="clinics" className={active == "clinics" ? "active" : ""}>
                                <Link to="/dashboard/clinics" tabIndex="0" role="button" aria-label="Lista poradni" className="submenu">
                                    <i className="material-icons" aria-hidden={true}>medication</i>
                                    <span>Poradnie</span>
                                </Link>
                                <ul>
                                    <li className={active == "clinics" && activeChildren == "new" ? "active" : ""}>
                                        <Link to="/dashboard/clinics/new" tabIndex="0" role="button" aria-label="Dodaj nową poradnie">
                                            <i className="material-icons" aria-hidden={true}>add</i>
                                            <span>Nowa poradnia</span>
                                        </Link> 
                                    </li>
                                </ul>
                            </li>
                            } */}
                            {/* {userPermissions && userPermissions.includes("page-autotests-answers") &&
                            <li id="autotests-answers" className={active == "autotests-answers" ? "active" : ""}>
                                <Link to="/dashboard/autotests-answers" tabIndex="0" role="button" aria-label="Lista odpowiedzi użytkowników do autotestów">
                                    <i className="material-icons" aria-hidden={true}>quiz</i>
                                    <span>Autotesty - odpowiedzi</span>
                                </Link>
                            </li>
                            } */}
                        </ul>               
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
