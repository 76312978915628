import React, { useState, useEffect, useRef} from "react"
import Input from './../input/Input';
import Select from './../select/Select';

export default props => {
    let [error, setError] = useState(false);
    let [load, setLoad] = useState(false);
    let [errorText, setErrorText] = useState(false);
    // let [ target, setTarget ] = useState("false");
    // let [ url, setUrl ] = useState("");
    // let [ text, setText ] = useState("");
    // let [ uppercase, setUppercase ] = useState("initial");

    useEffect(() => {
        if (!load) {
            // checkDialog();
            checkFields();
            setLoad(true);
        }
    }, [load])

    const checkFields = () => {
        if(!props.value) {
            if(props.onlyLink) {
                props.setFunction({ target: "false", url: "" })
            }else{
                props.setFunction({ target: "false", url: "", text: "", uppercase: "initial" })
            }
        }
    }

    const options = [
        {
            value: "false",
            text: "tej samej strony"
        },
        {
            value: "true",
            text: "zewnętrznej strony"
        },
    ]
    const uppercaseOptions = [
        {
            value: "initial",
            text: "bez zmian"
        },
        {
            value: "uppercase",
            text: "dużymi literami"
        },
    ]
    useEffect(() => {
    }, [props.forceValidate])
    return (
        <div className={error ? "link-component error" : "link-component"} data-alert={errorText}>
            {props.label &&
                <label>{props.label}</label>
            }
            <div className={props.label ? "border" : ""}>
                {load && props.value &&
                    <>
                    {!props.onlyLink &&
                        <Input  required={ true }
                            setFunction={ val => props.setFunction({ target: props.value.target, url: props.value.url, text: val, uppercase: props.value.uppercase }) }
                            value={props.value.text}
                            label={ 'Tekst linku' }
                            regExp={ /.*/ }
                            minChar={ 3 } 
                            forceValidate={ props.forceValidate } 
                        />
                    }
                        <Input  required={ true }
                            setFunction={ val => props.setFunction({ target: props.value.target, url: val, text: props.value.text, uppercase: props.value.uppercase }) }
                            value={props.value.url}
                            label={ 'Link do strony' }
                            regExp={ /.*/ }
                            minChar={ 3 } 
                            forceValidate={ props.forceValidate } 
                        />
                        <label>Linkuje do</label>
                        <Select name="status"
                            data={ options } 
                            value={ props.value.target }
                            handle={ (val) => props.setFunction({ target: val, url: props.value.url, text: props.value.text, uppercase: props.value.uppercase }) } 
                        />
                        {!props.onlyLink &&
                            <>
                                <label>Tekst linku</label>
                                <Select name="status"
                                    data={ uppercaseOptions } 
                                    value={ props.value.uppercase }
                                    handle={ (val) => props.setFunction({ uppercase: val, url: props.value.url, text: props.value.text, target: props.value.target }) } 
                                />
                            </>
                        }
                    </>
                }
            </div>
            {error && errorText &&
                <span className="error-text">{errorText}</span>
            }
        </div>
    )
}