import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from './../select/Select';

const SelectItems = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ selected, setSelected ] = useState([]);
    let [ data, setData ] = useState([]);
    let [ selectedCategory, setSelectedCategory ] = useState();
    let [ error, setError ] = useState(false);
    let [ optionsCategory, setOptionsCategory ] = useState([]);
    
    useEffect(() => {
        if(props.value && (props.value).length > 0) {
            setSelected(props.value)
        }
    }, [props.value])

    useEffect(() => {
        if(props.type == "category") {
            if(!load) {
                setData(props.data);
                if(props.value && props.value.length > 0) {
                    setSelected(props.value);
                }
                setLoad(true);
            }
        }else{
            if(props.filter) {
                if(props.data) {
                    generateData('all');
                    setLoad(true)
                }
            }else{
                if(props.data && (props.data).length > 0) {
                    generateData('all');
                    setLoad(true)
                }
            }
        }
    }, [props.data])

    useEffect(() => {
        if ( load ) {
            validate();
        }
    }, [props.forceValidate])

    const validate = () => {
        if ( selected && selected.length === 0) {
            // setErrorText('To pole jest wymagane.')
            setError(true);
            return false;
        } else {
            setError(false);
        }

        return true;
    }

    const generateData = (type) => {
        if(type == 'all') {
            setData(props.data)
            setSelectedCategory(0)
        }

        if(props.filter) {
            let categoryList = [];
            Object.keys(props.data).map((label, i) => {
                if(i == 0) {
                    setSelectedCategory(label)
                }
                categoryList = [...categoryList, {
                    value: label,
                    text: changeText(label),
                }]
            })
            setOptionsCategory(categoryList);
        }
    }

    const changeText = (text) => {
        if(text == "articles") {
            text = "Wpisy";
        }else if(text == "pages") {
            text = "Strony";
        }else if(text == "products") {
            text = "Produkty";
        }else if(text == "brands") {
            text = "Marki";
        }else if(text == "colors") {
            text = "Kolory";
        }else if(text == "collections") {
            text = "Kolekcje";
        }else if(text == "technical-description") {
            text = "Opisy techniczne";
        }else if(text == "marketing-support") {
            text = "Marketing support";
        }else if(text == "realizations") {
            text = "Realizacje";
        }else if(text == "accessories") {
            text = "Akcesoria";
        }

        return text;
    }

    const addSelected = (item) => {
        if(props.type == "category") {
            if(props.limit > selected.length || !props.limit) {
                let status = true;
                selected && selected.filter(sel => {
                    if(sel.id == item.id && sel.type == item.type) {
                        status = false;
                    } 
                })
        
                if(status) {
                    setSelected([...selected, item])
                    props.handle([...selected, item])
                }
            }
        }else{
            if(props.limit > (!props.value ? 0 : (props.value).length) || !props.limit) {
                let status = true;
                selected && selected.filter(sel => {
                    if(sel.id == item.id && sel.type == item.type) {
                        status = false;
                    } 
                })
        
                if(status) {
                    setSelected([...selected, item])
                    props.handle([...selected, item])
                }
            }
        }
    }

    const removeSelected = (item) => {
        let newSelected = selected.filter(sel => {
            if(sel.id !== item.id) {
                return sel;
            }else{
                if(sel.type !== item.type) {
                    return sel;
                }
            }
        })
        setSelected(newSelected)
        props.handle(newSelected)
    }

    const checkClass = (itemData) => {
        let newClass = ""
        selected.filter(item => {
            if(item && itemData && item.id == itemData.id && item.type == itemData.type) {
                newClass = "disabled";
            }
        })
        return newClass;
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(selected);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        props.handle(items)
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    return (
        <div className={"select-items-section" + (error ? " error" : "")} data-alert={"Należy wybrać"}>
            <label> { props.label } </label>
            {props.filter &&
                <div className="select-wrapper">
                    {/* <select className="form-control" onInput={ (e) => setSelectedCategory(e.target.value) }>
                        {props.data && props.data.map((item, index) => (
                            <option value={index}>{item.name}</option>
                        ))}
                    </select> */}
                    <Select name="category"
                        data={ optionsCategory } 
                        value={ props.selectedCategory }
                        handle={ (val) => setSelectedCategory(val) } 
                        class="btn-primary btn-simple" />
                </div>
            }
            <div className="box-select">
                {props.filter &&
                <div className="col-select">
                    {data && data[selectedCategory] && data[selectedCategory].map((item, index) => (
                        <div className={"row-item " + checkClass(item)} key={index} onClick={ () => addSelected(item)}>
                            <span>{ item.title }</span>
                        </div>
                    ))}
                </div>
                }
                {!props.filter &&
                <div className="col-select">
                    {data && data.length > 0 && data.map((item, index) => (
                        <div className={"row-item " + checkClass(item)} 
                            key={index} 
                            onClick={ () => addSelected(item)} 
                            onKeyDown={(e) => handleKeyDown(e, addSelected, item)} 
                            tabIndex={checkClass(item) ? "-1" : "0"} 
                            role="button" 
                            aria-label={`Wybierz ${(props.label != "Użytkownicy" ? (item.name ? item.name : item.title) : item.name + ' ' + item.surname)}`}>
                            <span>{ (props.label != "Użytkownicy" ? (item.name ? item.name : item.title) : item.name + ' ' + item.surname) }</span>
                        </div>
                    ))}
                </div>
                }
                <DragDropContext onDragEnd={handleRLDDChange}>
                    <Droppable droppableId="selected-items">
                        {(provided) => (
                            <div className={"col-select selected-items" + ( error ? ' error ' : '')} {...provided.droppableProps} ref={provided.innerRef}>
                            {selected && selected.map((sel, index) => sel && (
                                <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                    {(provided) => provided && (
                                        <div className="row-item" 
                                            key={index} 
                                            onClick={ () => removeSelected(sel)} {...provided.draggableProps} 
                                            ref={provided.innerRef} {...provided.dragHandleProps} 
                                            onKeyDown={(e) => handleKeyDown(e, removeSelected, sel)} 
                                            tabIndex={"0"} 
                                            role="button" 
                                            aria-label={`Odznacz`}>
                                            <span>{ (props.label != "Użytkownicy" ? (sel.title ? sel.title : sel.name) : sel.name + ' ' + sel.surname) }</span>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>   
    );
}

export default SelectItems;
