import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Button from './../button/Button';
import MediaLoad from './../media-load/MediaLoad';
import SelectItems from './../select-items/SelectItems';
import { useSelector, useDispatch } from 'react-redux'

const FilesDownload = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ categoriesData, setCategoriesData ] = useState(false);

    let categories = useSelector(state => state.categories);
    let lang = useSelector(state => state.lang);


    useEffect(() => {
        if(categories.length > 0 && !load) {
            createDataOnLoad();
            setLoad(true);
        }
    }, [load, categories])

    useEffect(() => {
        if(load) {
            createDataOnLoad();
        }
    }, [lang])

    useEffect(async() => {
        if(load) {
            let errors = await checkErrors();
            if(errors) {
                setErrors(true);
            }else{
                setErrors(false);
            }
        }
    }, [props.forceValidate])

    const updateFields = (val, index, label) => {
        let newData = props.data;
        newData.map((item, i) => i == index ? item[label] = val : item);
        props.setFunction(newData);
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.files-download.error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const handleKeyDown = (e, functionName, functionValue = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue)
        }
    }

    const rolledSection = (index) => {
        if(typeof window !== "undefined") {
            let sections = document.querySelectorAll(`.files-download .files-download-content`);
            sections[index].children[1].classList.toggle('active');
        }
    }

    const addItem = () => {
        // let newData;
        // if(props.data) {
        //     newData = [...props.data, {
        //         file: '',
        //         category: '',
        //     }]
        // }else{
        //     newData = [{
        //         file: '',
        //         category: '',
        //     }]
        // }
        let newData = [...props.data, {
            file: '',
            category: '',
        }]
        props.setFunction(newData);
    }

    const removeItem = (index) => {
        let newData = props.data.filter((item, i) => i == index ? false : item);
        props.setFunction(newData);
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    const handleRLDDChange = (result) => {
        if (!result.destination) return;
        const items = Array.from(props.data);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        props.setFunction(items);
    }

    const createDataOnLoad = () => {
        let newData = [];
        categories && categories.filter((cat, index) => {
            if(cat.name == "Kategorie plików") {
                cat.childrens && cat.childrens.map((item, i) => {
                    if(lang.id && item.language == lang.id ) {
                        let newItem = {
                            id: item.id,
                            name: item.name,
                            parent: item.parent,
                            language: item.language,
                            update: false,
                        }
                        newData = [...newData, newItem]
                    }
                })
            }
        })
        setCategoriesData(newData);
    }
    return (
        <div className={error ? `section-wrapper files-download error-section` : `section-wrapper files-download`}>
                <DragDropContext onDragEnd={handleRLDDChange}>
                <Droppable droppableId="section-items">
                    {(provided) => (
                        <div className="section-items" {...provided.droppableProps} ref={provided.innerRef}>
                        {props.data && props.data.map((item, index) => (
                            <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                {(provided) => provided && (
                                    <div className="files-download-content" {...provided.draggableProps} ref={provided.innerRef}>
                                        <div className="section-index">
                                            <span onClick={() => rolledSection(index)}
                                                aria-label="Zwiń lub rozwiń sekcje"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, rolledSection, index)}>Plik {index + 1}</span>
                                            {!error &&
                                            <i {...provided.dragHandleProps} 
                                                className="material-icons"
                                                aria-label="Przeciągnij sekcje"
                                                tabIndex="0"
                                                role="button">open_with</i>
                                            }
                                            <i onClick={() => removeItem(index)} 
                                                aria-label="Usuń sekcje"
                                                tabIndex="0"
                                                role="button"
                                                onKeyDown={(e) => handleKeyDown(e, removeItem, index)}
                                                className="material-icons remove-item-icon">delete</i>
                                        </div>
                                        <div className="content">
                                            <div className="row-section">
                                                <MediaLoad setFunction={ val => updateFields(val, index, 'file') } value={item.file} label="Załącznik" forceValidate={ props.forceValidate } only="attachment" />
                                            </div>
                                            <div className="row-section">
                                                {categoriesData &&
                                                    <div className="select-boxes">
                                                        <SelectItems
                                                            label="Rodzaj pliku"
                                                            data={ categoriesData } 
                                                            value={ item.category }
                                                            limit={ 1 }
                                                            handle={ (val) => updateFields(val, index, 'category') } 
                                                            forceValidate={ props.forceValidate } 
                                                            type="category"
                                                            column="xs-12" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className="add-item-in-section">
                <Button title="Dodaj plik" setFunction={() => addItem()} />   
            </div>
        </div>
    );
}

export default FilesDownload;