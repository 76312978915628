import React, { useState, useEffect } from 'react';
import FocusTrap from 'focus-trap-react';
import axios from 'axios';
import ApiUrl from './../../../services/ApiUrl';
import Button from './../../../components/button/Button';
import Upload from './../../../components/input/Upload';
import Select from './../../../components/select/Select';
import Input from './../../../components/input/Input';
import { useDispatch } from 'react-redux';
import ReactPlayer from 'react-player/lazy'

const UploadPopup = props => {
    const dispatch = useDispatch();
    let [ uploadType, setUploadType ] = useState("file");
    let [ selectedFile, setSelectedFile] = useState(null);
    let [ fileNames, setFileNames ] = useState(null);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ disableBtn, setDisableBtn ] = useState(false);

    useEffect(() => {
        console.log(fileNames);
    }, [fileNames])

    let optionsUpload = [
        {
            value: "file",
            text: "Wgraj plik",
            ariaLabel: "Wgraj plik z komputera"
        },
        {
            value: "url",
            text: "Wprowadź link do yt",
            ariaLabel: "Wprowadź link do filmiku na youtube"
        },
    ]

    const onKeyPressCloseModal = (e) => {
        if(e.key === 'Enter') {
            props.close();
        }
    }

    const validate = async() => {

        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(!errors) {
            await upload();
        }
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.media-upload-popup .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }
    const uploadFile = async ( file, fileName ) => {
            const formData = new FormData(); 
            formData.append("file", file); 
            formData.append("fileName", fileName);
            formData.append('token', localStorage.getItem('token'));
            formData.append('userID', localStorage.getItem('userId'));
            formData.append('uploadType', uploadType);

            let sendPost = await axios({
                method: 'post',
                url: `${ ApiUrl() }/newImageLibrary`,
                config: { headers: {'Content-Type': 'multipart/form-data' }},
                data: formData,
            });
            let res = JSON.parse(sendPost.request.response);

            if(!res.status) {
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>${res.data}</p>` })
                return false;
            } else {
                return true;
            }
    }

    const upload = async() => {
        if(!disableBtn) {
            await setDisableBtn(true);
            await setDisableBtn(true);
            try {
                await selectedFile.map( async( el, i ) => {
                    let up = await uploadFile( el, fileNames[i] );

                    if ( up && selectedFile.length === i + 1 ) {
                        await setDisableBtn(false);
                        await props.close()  ;
                        await props.refreshGallery();
                    }
                });
                
            } catch (error) {
                await setDisableBtn(false);
                dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później ${error}</p>` }) 
            }
        }
    }

    const generateFiles = ( files ) => {
        let names = [];
        if(!files.length) {
            setSelectedFile([files])
            names.push((files.name).slice(0, files.name.indexOf('.')));
        }else{
            setSelectedFile( files );
            files && files.map( (file, index) => {
                names.push((file.name).slice(0, file.name.indexOf('.')));
            })
        }
        setFileNames( names );
    }

    const uploadYT = (url) => {
        let names = [];
        // setSelectedFile([
        //     {
        //         url: url,
        //         type: "yt",
        //     }
        // ])
        setSelectedFile([url])
        names.push('');
        setFileNames( names );
    }

    const editName = ( val, i ) => {
        let getNames = fileNames;

        getNames[i] = val;
        setFileNames(getNames);
    }

    return (
        <FocusTrap>
            <div className={ "media-upload-popup" + ( uploadType == "file" && selectedFile && selectedFile.length > 0 ? ' file' : '') }>
                <div className="popup" role="alert">
                    <div className="popup-wrapper">
                        <div className="popup-media-content">
                            <div className="item">
                                <Select name="language"
                                    ariaLabel="Wybierz jak chcesz wgrać plik"
                                    data={ optionsUpload } 
                                    value={ uploadType }
                                    label="Co chcesz zrobić?"
                                    handle={ (type) => { setUploadType(type); setSelectedFile(null) } } 
                                />
                            </div>
                            <div className="item">
                                {uploadType == "file" &&
                                    <Upload  
                                        setFunction={ val => generateFiles( val ) }
                                        value={ selectedFile }
                                        regExp={ /.*/ }
                                        forceValidate={forceValidate}
                                    />
                                }
                                {uploadType == "url" &&
                                    <Input 
                                        required={ true }
                                        setFunction={ val => uploadYT(val) }
                                        value={ selectedFile}
                                        placeholder={ "link do youtube" }
                                        regExp={ /.*/ }
                                        minChar={ 1 }
                                        forceValidate={forceValidate}
                                    />
                                }
                            </div>
                            <div className={ "item" + ( uploadType == "file" ? ' file' : ' yt') }>
                                <div className="repeater">
                                    { selectedFile && selectedFile.length > 0 && selectedFile.map( (el, i) => (
                                        <div className="img" key={ i }>
                                            {typeof(el) == "object" &&
                                                <div className="img-prev">
                                                    <img src={ URL.createObjectURL(el) } alt="" />
                                                </div>
                                            }
                                            {typeof(el) == "string" &&
                                                <div className="yt-prev">
                                                    <ReactPlayer playing controls url={el} width="265" height="150" />
                                                </div>
                                            }
                                            <div className="img-name">
                                                <Input 
                                                    required={ true }
                                                    setFunction={ val => editName(val, i) }
                                                    value={ fileNames[i] }
                                                    regExp={ /.*/ }
                                                    placeholder={ "Dodaj nazwę pliku" }
                                                    minChar={ 1 }
                                                    forceValidate={forceValidate}
                                                    class="btn-simple"
                                                />
                                            </div>
                                        </div>
                                    ) )}
                                    
                                </div>
                                
                            </div>
                            <div className="item">
                                <Button class={disableBtn ? "block" : ""} title={  disableBtn  ? "Wgrywanie..." :  "Wgraj" } setFunction={ () => validate() } class="btn-secondary" />
                            </div>
                        </div>
                        <div className="popup-header">
                            <span tabIndex="0" role="button" onClick={() => props.close()} onKeyPress={(e) => onKeyPressCloseModal(e)}>Zamknij<p>X</p></span>
                        </div>
                    </div>
                </div>
            </div>
        </FocusTrap>
    )
}

export default UploadPopup;