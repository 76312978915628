import React, { useState, useEffect } from 'react'
import Editor from '../../../editor/Editor';
import MediaLoad from '../../../media-load/MediaLoad';
import Input from './../../../input/Input';
import Select from './../../../select/Select';

let validateNumber = 0;
const ImageDescription = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    
    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                title: '',
                text: '',
                image: '',
                smallTextHeader: '',
                accessorBox: false,
                removeMarginImage: false,
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    title: '',
                    text: '',
                    image: '',
                    smallTextHeader: '',
                    accessorBox: false,
                    removeMarginImage: false,
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.image-description-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const options = [
        {
            value: "false",
            text: "Nie"
        },
        {
            value: "true",
            text: "Tak"
        },
    ]

    const optionsMargin = [
        {
            value: "false",
            text: "Nie"
        },
        {
            value: "true",
            text: "Tak"
        },
    ]

    return (
        <div className={error ? `section-wrapper image-description error-section image-description-${props.index}` : `section-wrapper image-description image-description-${props.index}`}>
                <div className="row-section">
                    <Input  required={ false }
                        setFunction={ val => updateValue(val, 'title') }
                        value={ props.data.title }
                        label={ 'Tytuł' }
                        regExp={ /.*/ }
                        minChar={ 3 } 
                        forceValidate={ forceValidate } 
                    />
                </div>
                <div className="row-section">
                    <Input  required={ false }
                        setFunction={ val => updateValue(val, 'smallTextHeader') }
                        value={ props.data.smallTextHeader }
                        label={ 'Mały nagłówek' }
                        regExp={ /.*/ }
                        minChar={ 3 } 
                        forceValidate={ forceValidate } 
                    />
                </div>
                <div className="row-section">
                    <Select name="status"
                        data={ options } 
                        label="Box typu akcesoria"
                        value={ String(props.data.accessorBox) }
                        handle={ (val) => updateValue(val, 'accessorBox') } 
                        class="btn-primary btn-simple" />
                </div>
                <div className="row-section">
                    <Select name="status"
                        data={ optionsMargin } 
                        label="Usuń margines nad zdjęciem"
                        value={ String(props.data.removeMarginImage) }
                        handle={ (val) => updateValue(val, 'removeMarginImage') } 
                        class="btn-primary btn-simple" />
                </div>
                <div className="row-section border">
                    <Editor required={true} forceValidate={ forceValidate } setFunction={ val => updateValue(val, 'text') } value={props.data.text} label="Treść" />
                </div>
                <div className="row-section">
                    <MediaLoad forceValidate={ forceValidate } setFunction={ val => updateValue(val, 'image') } value={props.data.image} label="Zdjęcie" />
                </div>
        </div>
    );
}

export default ImageDescription;