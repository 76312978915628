const ApiUrl = () => {

    let link = '';
    if ( window.location.hostname === 'localhost') {
        link = 'http://localhost/api'
        link = 'https://api.demo.norddigital.com/api'
    } else {
        link = 'https://api.demo.norddigital.com/api'
    }

    return link;
}

export default ApiUrl;