import React, { useState,useEffect } from 'react';
import { Route } from "react-router-dom";
import Login from './login/Login';
import Logo from './../../images/logo.png';
// import Logo from './../../images/logo-dorada.png'
// import Logo from './../../svg-components/Logo';
import ResetPassword from './reset-password/ResetPassword';
import SendResetPassword from './send-reset-password/SendResetPassword';
import axios from 'axios';
import ApiUrl from '../../services/ApiUrl';

let countUsers = 1;
const LoginView = (props) => {
    let [load, setLoad] = useState(0);

    useEffect(() => {
        if (load == 0) {
            ;(async function() {
                await sessionUser();
                await checkCountUser();
                await setLoad(1);
            })()
        }
    },[load]);

    const sessionUser = () => {
        var currentUser = localStorage.getItem('token') ? true : false;
        if (currentUser) {
            props.history.push({ pathname: '/dashboard'})
        }
    }

    const checkCountUser = async () => {
        await axios({
            method: 'get',
            url: `${ApiUrl()}/countUsers`,
            headers: { 
                'Access-Control-Allow-Origin' : '*',
            },
        }).then(response => {
            countUsers = response.request.response;
        });
    }

    // useEffect(() => {
    //     if ( !load ) {
    //         if(localStorage.getItem('contrast')) {
    //             let checkContrast = document.body.classList.contains('contrast-wcag');
    //             if(!checkContrast) {
    //                 document.body.classList.add('contrast-wcag');
    //             }
    //         }
    //     }
    // }, [load])

    return (
        <section className="login-wrapper">

            <div className="overlay"></div>

            <img src={Logo} alt="norddigital" />
            {/* <div className="logo">
                <Logo />
            </div> */}

            <div className="login">
                    <Route path="/login" exact component={Login} />
                    <Route path={`${props.match.url}/send-reset-password`} component={SendResetPassword} />
                    <Route path={`${props.match.url}/reset-password`} component={ResetPassword} />
            </div>

        </section>
    );
}

export default LoginView;