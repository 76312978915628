import React, { useState, useEffect, useRef } from 'react'
import Arrow from './../../svg-components/Arrow';
import ArrowBlue from './../../svg-components/ArrowBlue';

const Select = ( props ) => {
    let [ chooseIndex, setChooseIndex ] = useState(0);
    let [ load, setLoad ] = useState(false);
    let [ toggle, setToggle ] = useState(false);
    let ref = useRef();

    useEffect(() => {
        if ( load ) {
            setLoad(false)
        }
    }, [ props.reset])

    useEffect(() => {
        if(props.resetIndex && load) {
            setChooseIndex(0);
        }
    }, [props.resetIndex])
    
    useEffect(() => {
        if(props.value && typeof props.value == "string") {
            if ( !load && props.value && props.value.length > 0) {
                checkChooseValue();
                setLoad(true);
            }
        }else{
            if ( !load && props.value && props.value > 0) {
                checkChooseValue();
                setLoad(true);
            }
        }
    }, [load, props.value])
    const checkChooseValue = () => {
        let selectIndex = 0;
        props.data && props.data.map((option, index) => {
            if(option.value == props.value) {
                selectIndex = index
            }
        })
        setChooseIndex(selectIndex);
    }

    const update = (e) => {
        ref.current.focus();
        let target = e.target;
        let selectedIndex = target.getAttribute('data-index')
        let selectedValue = target.getAttribute('data-value')
        setChooseIndex(selectedIndex);
        setToggle(false);
        props.handle(selectedValue);
    }

    let handleKeyDownToggle = (e) => {
        if (e.key === 'Enter') {
            setToggle(!toggle);
        }
    }

    let handleKeyDownOption = (e) => {
        if (e.key === 'Enter') {
            update(e);
        }
    }

    return ( 
        <div className="select-wrap">
            {props.label &&
                <label for={ props.name }>{ props.label }</label>
            }
            <div className="select-wrapper">
                <div className={"select " + (props.class ? props.class : 'btn-primary') + (toggle ? " active" : "")} id={props.name} ref={ref} aria-label={props.data[chooseIndex]?.ariaLabel ? props.data[chooseIndex]?.ariaLabel : props.label} onClick={() => setToggle(!toggle)} onKeyDown={(e) => handleKeyDownToggle(e)} tabIndex="0" role="listbox">
                    <div className="selected">{props.data[chooseIndex]?.text}</div>
                    <ul className="list">
                        {props.data && props.data.map((item, index) => (
                            <>
                            {index != chooseIndex &&
                                <li data-index={index} onClick={(e) => update(e)} onKeyDown={(e) => handleKeyDownOption(e)} tabIndex="0" role="option" aria-label={item.ariaLabel} data-info={item?.info} data-value={item.value} value={item.value} key={index}>{item.text} {props.withUser && item?.user ? (" - " + item.user) : ""}</li>
                            }
                            </>
                        ))}
                    </ul>
                    <div className={toggle ? "arrow active" : "arrow"}>
                        { props.class == 'btn-simple' ? <ArrowBlue />: <Arrow /> } 
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Select;
