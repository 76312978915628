import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styles.scss'
import dayjs from 'dayjs';
import 'dayjs/locale/pl';

const ChatCard = ( { opened, closed, enterChat, openChat, deleteChat, user, data } ) => {
    let openedChats = useSelector(state => state.openedChats );

    const onKeyDown = (e, functionName, functionValue = false, functionValueSecond = false) => {
        if (e.key === 'Enter') {
            functionName(functionValue, functionValueSecond)
        }
    }

    return (
        <div className={"chat" + ( opened ? ' opened' : '') + ( closed ? ' closed' : '')}>
            <div className="details">
                <div className="det username"><span>Autor:</span> { data.user_name }</div>
                { data.user_email &&
                    <div className="det email"><span>Email:</span> { data.user_email }</div>
                }
                <div className="det time"><span>Kiedy:</span> { dayjs( data.created_at, true).locale('pl').format('DD MMMM HH:mm:ss') }</div>
                { (opened) &&
                    <div className="det admin"><span>Prowadzi{ closed } czat:</span> { data.moderator ? data.moderator.login : '' } </div>
                }
                {closed &&
                    <div className="det admin"><span>Zakończył czat:</span> { data.who_ended } </div>
                }
            </div>
            <div className="actions">
                { !closed && !opened && 
                    <div className="action enter" 
                         onClick={ () => enterChat() }
                         onKeyDown={(e) => onKeyDown(e, enterChat)}
                         role="button"
                         aria-label={ `Przejmij chat autor: ${ data.user_name } z daty ${ dayjs( data.created_at, true).locale('pl').format('DD MMMM HH:mm:ss') }` }
                         tabIndex='0'>Przejmij <i className="material-icons">login</i></div>
                }
                { (opened || closed) && 
                    <>
                        { openedChats && openedChats.indexOf( data.id ) === -1 &&
                            <div    className="action see" 
                                    onClick={ () => openChat() }
                                    onKeyDown={(e) => onKeyDown(e, openChat)}
                                    role="button"
                                    aria-label={ `Zobacz chat autor: ${ data.user_name } z daty ${ dayjs( data.created_at, true).locale('pl').format('DD MMMM HH:mm:ss') }` }
                                    tabIndex='0'>Zobacz <i className="material-icons">visibility</i></div>
                        }
                    </>
                }
                <div    className="action delete" 
                        onClick={ () => deleteChat() } 
                        onKeyDown={(e) => onKeyDown(e, deleteChat)}
                        role="button"
                        aria-label={ `Usuń chat autor: ${ data.user_name } z daty ${ dayjs( data.created_at, true).locale('pl').format('DD MMMM HH:mm:ss') }` }
                        tabIndex='0'>
                            Usuń <i className="material-icons">delete</i>
                </div>
            </div>
        </div>
    );
}

export default ChatCard;
